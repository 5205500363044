<template>
	<v-card class="rounded-sm">
		<v-card-title class="headline">
			<span class="primary--text">Forgot Password</span>
		</v-card-title>

		<!-- RESET FORM -->
		<v-card-text v-if="!success">
			<!-- error message -->
			<v-alert v-if="error" type="error" color="orange" text dense>{{ error }}</v-alert>

			<!-- otherwise show regular information -->
			<v-alert v-else type="info" color="info" text dense>Enter your email address below to have reset instructions sent to you</v-alert>

			<!-- email field -->
			<v-form @submit.prevent="submit">
				<v-text-field 
					v-model="email"
					:disabled="loading"
					label="Email address"
				/>
			</v-form>
		</v-card-text>

		<!-- displayed when request is successful -->
		<v-card-text v-else>
			<v-alert type="success" color="green" text dense>Thank you! You will receive reset instructions via email shortly</v-alert>
		</v-card-text>

		<v-card-actions>
			<v-btn v-if="!success" text color="primary" :loading="loading" @click="submit">Submit</v-btn>
			<v-spacer></v-spacer>
			<v-btn text to="/login" :disabled="loading">Back</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import api from 'chemapp/frontend/login/login-api';
import alerts from 'chemapp/shared/alerts';

export default {
	data()
	{
		return {
			loading: false,
			error: false,
			success: false,
			email: '',
		}
	},

	methods: {
		async submit()
		{
			this.loading = true;
			this.error = false;
			this.success = false;

			try
			{
				// request reset instructions
				var result = await api.post('/send_reset_email', { email: this.email });

				// if the request was not successful display an error
				if (!result.data.success)
				{
					this.error = result.data.msg;
				}
				// otherwise clear the form and show a success message
				else
				{
					this.error = false;
					this.success = true;
				}
			}
			catch(err)
			{
				// display error returned from server
				var msg = err.response ? err.response.data.error : err.message;
				this.success = false;
				this.error = msg;
			}
			finally
			{
				this.loading = false;
			}
		}
	}
}
</script>