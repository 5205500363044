<template>
	<v-card class="rounded-sm">
		<v-card-title class="headline">
			<span class="primary--text">Reset Password</span>
		</v-card-title>
				
		<v-card-text v-if="!valid">
			<v-alert type="warning" text dense color="red">Invalid reset link</v-alert>
		</v-card-text>

		<v-card-text v-else-if="!success">
			<v-alert v-if="error" text dense color="orange" type="warning">{{ error }}</v-alert>
			<v-alert v-else text dense color="info" type="info">Please enter your email address and new password below</v-alert>

			<v-form ref="form">
				<v-text-field
					v-model="form.email"
					label="Email Address"
					:rules="rules.email"
				/>

				<v-text-field
					v-model="form.password"
					type="password"
					label="Password"
					:rules="rules.password"
				/>

				<v-text-field
					v-model="form.password_confirmation"
					type="password"
					label="Confirm Password"
					:rules="rules.passwordConf"
				/>

			</v-form>
		</v-card-text>

		<v-card-text v-else>
			<v-alert text dense color="success" type="success">Your password has been reset successfully.</v-alert>
		</v-card-text>

		<v-card-actions v-if="valid">
			<v-btn v-if="!success" color="primary" :loading="loading" @click="submit">Reset Password</v-btn>
			<v-btn v-else color="primary" to="/login">Return to Login</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import api from 'chemapp/frontend/login/login-api';
import alerts from 'chemapp/shared/alerts';
import validate from 'chemapp/shared/validators';

export default {
	data()
	{
		return {
			valid: false,
			error: false,
			success: false,
			loading: false,

			form: {
				token: '',
				email: '',
				password: '',
				password_confirmation: '',
			},

			rules: {
				email: [
					v => !!v || 'Please enter your email address',
					v => validate.email(v) || 'Invalid email address',
				],

				password: [ 
					v => !!v || 'Please enter a new password',
					v => validate.password(v) || 'Passwords must be at least six characters long',
				],

				passwordConf: [
					v => v == this.form.password || 'Passwords do not match',
				]
			}
		}
	},

	methods: {
		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.loading = true;
			this.error = false;
			this.success = false;

			try
			{
				// attempt to reset password
				await api.post('/submit_reset_password', this.form);

				// notify user of success
				this.success = true;
			}
			catch(err)
			{
				// display error returned from server
				var msg = err.response ? err.response.data.error : err.message;
				this.success = false;
				this.error = msg;
			}
			finally
			{
				this.loading = false;
			}

		}
	},

	mounted()
	{
		if (window._initData && window._initData.tokenValid)
		{
			this.valid = true;
			this.form = { ...this.form, token: window._initData.tokenValue };
		}
	}
}
</script>