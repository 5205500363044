
import moment from 'moment';

export default {
	notEmpty(v)
	{
		return v != null && `${v}`.trim() != '';
	},

	phone(v)
	{
		var invalid = v.match(/[^0-9\-()\s]/);
		var replaced = v.replace(/[^0-9]/g, '');
		return invalid == null && replaced.length >= 10;
	},

	email(v)
	{
		var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
		return !!v && v.trim().match(re) != null; 
	},

	password(v, minLength)
	{
		var min = minLength || 6;
		return v.length >= min;
	},

	postalCode(pc)
	{
		var pc = pc.replace(/\s/g, '');
		return pc.match(/[a-z][0-9][a-z][0-9][a-z][0-9]/i) != null;
	},

	date(v, format)
	{
		return moment(v, format).isValid();
	},

	integer(v, nonzero)
	{
		var num = parseInt(v, 10);

		if (isNaN(num))
			return false;
		else if (nonzero && num == 0)
			return false;
		else
			return true;
	},

	numeric(v, nonzero)
	{
		if (isNaN(v))
			return false;
		else if (nonzero && v == 0)
			return false;
		else
			return true;
	}
}