<template>
	<v-card class="rounded-sm">
		<v-card-title class="headline">
			<span class="primary--text">User Login</span>
		</v-card-title>
		
		<v-form @submit="submit">
			<v-card-text>
				<v-alert :value="error != null" type="error">{{ error }}</v-alert>
				<v-text-field prepend-icon="mdi-account-circle" label="Username" type="text" v-model="username" autofocus></v-text-field>
				<v-text-field prepend-icon="mdi-lock" label="Password" type="password" v-model="password"></v-text-field>
			</v-card-text>

			<v-card-actions>
				<v-btn text color="primary" type="submit" :loading="processing" :disabled="processing" @click="submit">Login</v-btn>
				<v-spacer/>
				<v-btn text type="button" to="/forgot_password">Forgot Password</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import axios from 'axios';

import alerts from 'chemapp/shared/alerts';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').getAttribute('content');

export default {
	data()
	{
		return {
			processing: false,
			username: '',
			password: '',
			error: null
		}
	},

	methods: {
		async submit(event)
		{
			var data = { 
				username: this.username,
				password: this.password,
			};

			event.preventDefault();
			event.stopPropagation();

			try
			{
				var result;

				this.processing = true;
				this.error = null;
				
				result = await axios.post('/authenticate', data);
				
				if (result.data.success)
				{
					await alerts.show('Login success', 'Success', 'success');
					document.location = '/users/';
				}
				else
				{
					alerts.show('Invalid username or password', 'Login Failed', 'error');
				}
			}
			catch(err)
			{
				this.error = err.response ? err.response.data.error : err.message;
			}
			finally
			{
				this.processing = false;
			}

			return false;
		},
	}
}

</script>