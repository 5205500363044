
import axios from 'axios';

import alerts from 'chemapp/shared/alerts';

// add support for Rails CSRF protection
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name=csrf-token]').getAttribute('content');

export function createApi(baseUrl)
{
	var api = axios.create({
		baseURL: baseUrl,
	});

	// setup global error handling
	api.interceptors.response.use(response => {
		return response;
	}, function(error) {
		var msg = error.response ? error.response.data.error : error.message;
		
		console.log('API call encountered an error');
		console.log({ error });
		console.log(msg);

		// use ugly JS alert for now
		//alert(msg, 'Error', 'error');
		alerts.show(msg, 'Error', 'error');

		return Promise.reject(error);
	});

	return api;
}

