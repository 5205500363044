
import 'vuetify/dist/vuetify.min.css';

import Vue from 'vue';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';

// Main login screen definition
import LoginMain from 'chemapp/frontend/login/comp/LoginMain.vue';
import LoginScreen from 'chemapp/frontend/login/comp/LoginScreen.vue';
import ForgotPassword from 'chemapp/frontend/login/comp/ForgotPassword.vue';
import ResetPassword from 'chemapp/frontend/login/comp/ResetPassword.vue';

const themeColors = {
	//primary: '#ff4200',
	primary: '#00aeef',
	accent: '#6bd1f7',
	secondary: "#02568a",
	'brand-orange': '#ff4200',
};

// configure vuetify
Vue.use(Vuetify);
const vuetify = new Vuetify({
	theme: {
		themes: {
			dark: themeColors,
			light: themeColors,
		},
		dark: false, // If you want to set dark theme then dark:true else set to false
	},
});

// configure routing
Vue.use(VueRouter);
const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			name: 'home',
			path: '/',
			redirect: '/login',
		},
		{
			name: 'login-main',
			path: '/login',
			component: LoginScreen
		},
		{
			name: 'forgot-password',
			path: '/forgot_password',
			component: ForgotPassword
		},
		{
			name: 'reset-password',
			path: '/reset_password',
			component: ResetPassword
		},
	]
});

// initialize main vue instance
const app = new Vue({
	el: '#app-root',
	vuetify,
	router,

	render(h) {
		return h(LoginMain);
	}
});